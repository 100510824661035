body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #454545!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-shadow {
  -webkit-box-shadow: 0px 6px 31px -8px rgba(61,61,61,0.88);
  -moz-box-shadow: 0px 6px 31px -8px rgba(61,61,61,0.88);
  box-shadow: 0px 6px 31px -8px rgba(61,61,61,0.88);
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

.profile-card {
  box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}

.floating-project {
  box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}

.profile-header {
  background: #70e1f5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffd194, #70e1f5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;
  position: relative;
  height: 100vh;
  display: flex;
}

.profile-header > svg {
  position: absolute;
  bottom: 0;
}

.profile-cta {
  padding-top: 100px;
}

.link:hover {
  color: rgb(0, 104, 104)!important;
}

a {
  color: #009d9d
}

a:hover {
  color: rgb(0, 104, 104)!important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(to bottom, #ffd194, #70e1f5);
}

.tech-panel:hover {
  box-shadow: 0 4px 20px 2px rgba(0,0,0,0.6);
  transition: box-shadow 0.2s ease-in-out;
}

.projects-container {
  background: #efefef;
  padding: 150px 0;
}